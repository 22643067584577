import './App.css';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import getAssetList from './task/getAssetList';
import Select from 'react-select';
import { ORGID, publicToken } from './constants.mjs';
//import dateFormat from 'dateformat';
import { v4 as uuidv4 } from 'uuid';

function App() {
  const [selectedStyle, setSelectedStyle] = useState('');
  const [selectedDesign, setSelectedDesign] = useState('');
  const [selectedView, setSelectedView] = useState('');
  const [styles, setStyles] = useState(null);
  const [designs, setDesigns] = useState(null);
  const [views, setViews] = useState(null);
  const [renderLogo, setRenderLogo] = useState(false);

  const [selectedAdultStyle, setSelectedAdultStyle] = useState('');
  const [selectedStyleVersion, setSelectedStyleVersion] = useState('');
  const [youthVersionMessage, setYouthVersionMessage] = useState('');
  const [adultStyles, setAdultStyles] = useState(null);
  const [youthStyle, setYouthStyle] = useState(null);
  const [styleVersion, setStyleVersion] = useState(null);
  const [transferId, setTransferId] = useState('');
  const [transferMessage, setTransferMessage] = useState('');

  const firstUpdate = useRef(true);

  useEffect(() => {
    const getThreekitStyles = async () => {
      const response = await getAssetList(ORGID, publicToken, {
        tags: `["type_style-ua"]`,
        type: 'item',
      });
      const platformStyles = response.map((style) => {
        let armourfuse = false;
        const armourfuseProperty = style.product.attributes.find(
          (attr) => attr.proxy && attr.proxy.name === 'Style Category'
        );
        if (
          armourfuseProperty &&
          armourfuseProperty.defaultValue === 'Armourfuse'
        ) {
          armourfuse = true;
        }
        return {
          value: style.product.name,
          label: style.product.name,
          armourfuse,
        };
      });
      setStyles(platformStyles);
    };

    getThreekitStyles();
  }, []);

  useEffect(() => {
    const defaultOption = [{ value: '', label: 'All' }];
    setDesigns(defaultOption);
    const getThreekitDesigns = async () => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      document.getElementById('submit').disabled = false;
      if (!selectedStyle.armourfuse) {
        return;
      }
      const response = await getAssetList(ORGID, publicToken, {
        tags: `["type_design-ua","style-ua_${selectedStyle.value}"]`,
        type: 'item',
      });
      const platformDesigns = response
        .filter((design) => {
          const { tags } = design.product;
          return !tags.includes('overlay-inside');
        })
        .map((design) => {
          return { value: design.product.name, label: design.product.name };
        });
      platformDesigns.unshift(defaultOption[0]);
      setDesigns(platformDesigns);
    };
    getThreekitDesigns();
  }, [selectedStyle]);

  useEffect(() => {
    const getViews = async () => {
      const viewOptions = [
        { value: 'All', label: 'All' },
        { value: 'Default', label: 'Default' },
        { value: 'Front', label: 'Front' },
        //2023-04 MS reduce the number of renders by cutting unused views
        //{ value: 'Back', label: 'Back' },
        //{ value: 'Right', label: 'Right' },
        //{ value: 'Left', label: 'Left' },
      ];
      setViews(viewOptions);
    };
    getViews();
  }, []);

  useEffect(() => {
    const getThreekitStyles = async () => {
      const response = await getAssetList(ORGID, publicToken, {
        tags: `["type_style-ua"]`,
        type: 'item',
      });
      const platformStyles = response.map((style) => {
        let armourfuse = false;
        const armourfuseProperty = style.product.attributes.find(
          (attr) => attr.proxy && attr.proxy.name === 'Style Category'
        );
        if (
          armourfuseProperty &&
          armourfuseProperty.defaultValue === 'Armourfuse'
        ) {
          armourfuse = true;
        }
        return {
          value: style.product.name,
          label: style.product.name,
          armourfuse,
        };
      });
      setAdultStyles(platformStyles);
    };

    getThreekitStyles();
  }, []);

  useEffect(() => {
    const getStyleVersion = async () => {
      const versionOptions = [
        { value: 'Youth', label: 'Youth' },
        { value: 'Girl', label: 'Girl' },
      ];
      setStyleVersion(versionOptions);
    };
    getStyleVersion();
  }, []);

  function handleCheckBoxChange(e) {
    setRenderLogo(e.target.checked);
  }
  function handleTransferIdChange(e) {
    setTransferId(e.target.value);
  }
  function handleYouthStyleChange(e) {
    setYouthStyle(e.target.value);
  }
  function checkCopyStatus(e) {
    e.preventDefault();
    //console.log('Testing transferId is', transferId);
    const TRANSFER_TABLE_STATUS_ID = 'b535941e-9b87-4756-90ae-d8dd5906e366';
    const GET_DATATABLES_URL = `https://preview.threekit.com/api/datatables/${TRANSFER_TABLE_STATUS_ID}/rows?orgId=${ORGID}&bearer_token=${publicToken}&all=true`;
    axios({
      method: 'get',
      url: GET_DATATABLES_URL,
    })
      .then((res) => res.data.rows.map((item) => item.value))
      .then((res) => {
        //console.log('status table data:', res);
        const theCurrentTransfer =
          res && typeof res === 'object'
            ? res.find((row) => row.TransId === transferId)
            : undefined;
        let transferInfoTxt = '';
        if (theCurrentTransfer) {
          transferInfoTxt = `Copying from ${theCurrentTransfer.From_Style} to ${theCurrentTransfer.To_Style} with Design: ${theCurrentTransfer.Design} - View: ${theCurrentTransfer.StageView} - id ${theCurrentTransfer.TransId} at GMT ${theCurrentTransfer.TimeStamp} `;
          const newMessage =
            theCurrentTransfer.Status === '100'
              ? transferInfoTxt + ' ==>> is finished!'
              : transferInfoTxt + ' ==>> is still running!';
          setTransferMessage(newMessage);
        } else {
          setTransferMessage(transferMessage + ' !!! no data found!');
        }
      })
      .catch((err) => {
        console.log('Getting status from datatable has error:', err);
        setTransferMessage(
          'Getting status from datatable has error:' + err.message
        );
      });
  }
  function handleSubmit(e) {
    e.preventDefault();

    axios.post('/renderUA', {
      style: selectedStyle.value,
      design: selectedDesign.value,
      view: selectedView.value,
      options: JSON.stringify({ renderLogo }),
    });
    alert(
      `UA Job submitted for ${selectedStyle.value} - Design:${
        selectedDesign.value
      } - View:${
        selectedView.value ? selectedView.value : 'All'
      } - Render Logo ? ${renderLogo}`
    );
  }
  function createUAItems(e) {
    e.preventDefault();
    axios.post('/createUA');
    alert('Triggered automated item creation and asset update for UA.');
  }

  async function createYouthItems(e) {
    e.preventDefault();

    if (!selectedAdultStyle.value) {
      alert(`Please select an Adult Style!`);
      return;
    }
    const versionSelected_Str = selectedStyleVersion.value
      ? selectedStyleVersion.value
      : 'Youth';
    if (!youthStyle) {
      alert(
        `Please input a Style name for the ${versionSelected_Str} version!`
      );
      return;
    }
    if (
      !selectedAdultStyle.value.toUpperCase().endsWith('M') &&
      !selectedAdultStyle.value.toUpperCase().endsWith('W')
    ) {
      alert(`Please select a Style that its name ends with 'M' or 'W'!`);
      return;
    }
    if (
      selectedAdultStyle.value.toUpperCase().endsWith('M') &&
      (!youthStyle.toUpperCase().endsWith('Y') ||
        versionSelected_Str !== 'Youth')
    ) {
      alert(
        `For ${selectedAdultStyle.value} please select Youth Version and use letter 'Y' at the end for the Youth Version name!`
      );
      return;
    }
    if (
      selectedAdultStyle.value.toUpperCase().endsWith('W') &&
      (!youthStyle.toUpperCase().endsWith('G') ||
        versionSelected_Str !== 'Girl')
    ) {
      alert(
        `For ${selectedAdultStyle.value} please select Girl Version and use letter 'G' at the end for the Girl Version name!`
      );
      return;
    }

    //When it's armourfuse check to see if there's any Design in the adult style
    if (selectedAdultStyle.armourfuse) {
      const designItems = await getAssetList(ORGID, publicToken, {
        tags: `["type_design-ua","style-ua_${selectedAdultStyle.value}"]`,
        type: 'item',
      });
      if (!designItems[0]) {
        alert(
          `Style ${selectedAdultStyle.value} doesn't have any Design! Can't create ${versionSelected_Str} version!`
        );
        return;
      }
    }
    //after validation start creating the youth/girl version:
    const trans_id = uuidv4();
    axios.post('/createYouth', {
      style: selectedAdultStyle.value,
      youthStyle: youthStyle,
      options: {
        group: versionSelected_Str,
        copyRenderTransId: trans_id,
      },
    });
    setYouthVersionMessage(
      `Please use ${trans_id} to check render copy status (input field and button located below.)`
    );
    alert(
      `Triggered ${versionSelected_Str} version of ${selectedAdultStyle.value}, Style ${youthStyle} creation and Render copying.`
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Select your style and optionally your design below</h1>
        <form onSubmit={handleSubmit}>
          <h3> Style selection </h3>
          <Select
            defaultValue={selectedStyle}
            onChange={setSelectedStyle}
            options={styles}
          />
          <h3> Design selection </h3>
          <Select
            placeholder="All"
            defaultValue={selectedDesign}
            onChange={setSelectedDesign}
            options={designs}
          />
          <h3> View selection </h3>
          <Select
            placeholder="All"
            defaultValue={selectedView}
            onChange={setSelectedView}
            options={views}
          />
          <div>
            <label>
              <input
                type="checkbox"
                checked={renderLogo}
                onChange={handleCheckBoxChange}
              />
              Render UA Logo
            </label>
          </div>
          <input
            type="submit"
            id="submit"
            value="Render Permutations"
            disabled
          ></input>

          <h3>
            <br />
          </h3>
          <h3>
            <br />
          </h3>
          <table
            style={{
              width: '98%',
              border: '1px solid #b3adad',
              borderCollapse: 'collapse',
              textAlign: 'left',
              padding: '5px',
            }}
          >
            <thead>
              <tr
                style={{
                  border: '1px solid #b3adad',
                  padding: '25px',
                  background: '#000000',
                  color: '#eaeaea',
                }}
              >
                <th>Purpose</th>
                <th></th>
                <th>Tags</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td rowSpan="4">
                  Create Datatable, Style item;
                  <br />
                  Create/update Location Bundle
                </td>
                <td>For Armourfuse</td>
                <td>
                  <h2>create-style-AF</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>For Armourfuse Reversible</td>
                <td>
                  <h2>create-style-AFR</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>For UA Stock</td>
                <td>
                  <h2>create-style-UAST</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>For UA Stock Reversible</td>
                <td>
                  <h2>create-style-UASTR</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>
                  Refresh Decoration Bundle <br />
                  with latest Data Table locations
                </td>
                <td>&nbsp;</td>
                <td>
                  <h2>refresh</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>Add Braid Jerseys</td>
                <td>&nbsp;</td>
                <td>
                  <h2>add-braid-jerseys</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>Add Braid Pants</td>
                <td>&nbsp;</td>
                <td>
                  <h2>add-braid-pants</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>Add Stitching</td>
                <td>&nbsp;</td>
                <td>
                  <h2>add-stitching</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td rowSpan="4">
                  Update model asset only <br />
                  with attributes and rules
                </td>
                <td>For Armourfuse</td>
                <td>
                  <h2>create-model-AF</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>For Armourfuse Reversible</td>
                <td>
                  <h2>create-model-AFR</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>For UA Stock</td>
                <td>
                  <h2>create-model-UAST</h2>
                </td>
              </tr>
              <tr style={{ border: '1px solid #b3adad', color: '#313030' }}>
                <td>For UA Stock Reversible</td>
                <td>
                  <h2>create-model-UASTR</h2>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            onClick={createUAItems}
            style={{ fontSize: '25px', marginTop: '50px' }}
          >
            Create UA Items & Update Assets
          </button>
          <h3>
            <br />
          </h3>

          <h1>Create Youth/Girl Version of a Style</h1>
          <h3> Adult Style selection </h3>
          <Select
            defaultValue={selectedAdultStyle}
            onChange={setSelectedAdultStyle}
            options={adultStyles}
          />
          <h3> Version selection </h3>
          <Select
            placeholder="Youth"
            defaultValue={selectedStyleVersion}
            onChange={setSelectedStyleVersion}
            options={styleVersion}
          />
          <input
            type="text"
            value={youthStyle}
            placeholder="Please input the Style name of the Youth or Girl version"
            onChange={handleYouthStyleChange}
            style={{ width: '300px', height: '30px', margin: '5px' }}
          />
          <button
            onClick={createYouthItems}
            style={{ fontSize: '25px', marginTop: '50px' }}
          >
            Create Youth/Girl version Style & Copy Renders over
          </button>
          <div>
            <p>{youthVersionMessage}</p>
          </div>
          <div>
            <p>{transferMessage}</p>
          </div>
          <input
            type="text"
            value={transferId}
            placeholder="Please input the id of the copy task to check status"
            onChange={handleTransferIdChange}
            style={{ width: '300px', height: '30px', margin: '5px' }}
          />
          <button
            id="checkCopyStatus"
            onClick={checkCopyStatus}
            style={{ fontSize: '20px', marginTop: '10px' }}
          >
            Check Copy Status
          </button>
        </form>
      </header>
    </div>
  );
}

export default App;
