import axios from 'axios';
import querystring from 'querystring';
const API_PATH = 'https://preview.threekit.com/api/';
const GET_ASSETS = 'products/export/json';
//const ORGID = 'bed6e3c9-e781-42ac-a676-90b0e5ab4c33';
//const TOKEN = 'a6e61acb-28db-434d-8970-c6009d6977cd';

const getAssetList = (orgId, authToken, queryParams) => {
  const url = `${API_PATH}${GET_ASSETS}?orgId=${orgId}&bearer_token=${authToken}&all=true${
    queryParams ? `&${querystring.stringify(queryParams)}` : ''
  }&all=true`;
  return axios({
    method: 'get',
    url,
  }).then((res) => res.data);
};

//getAssetList(ORGID, TOKEN, query);

export default getAssetList;
